const React = require('react');
const {GlobalConfigProvider} = require('./global-config-provider');

const wrapRootElement = ({element}) => {
    return (
        <GlobalConfigProvider>
            {element}
        </GlobalConfigProvider>
    )
}

module.exports = {
    wrapRootElement
}