import {useEffect} from 'react';
import {useUserState} from "../user-context";

const NO_OP_CALLBACK = () => {};
const useInitiatedAuthenticationCallback = (callback = NO_OP_CALLBACK) => {
    const {authenticated, initiated, user} = useUserState();

    useEffect(() => {
        if(initiated === true){
            callback(authenticated, user);
        }
    }, [authenticated, initiated]);

    return {authenticated, initiated, user};
}

export {useInitiatedAuthenticationCallback}