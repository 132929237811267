import React, {useRef, useEffect} from 'react';
import PasswordValidator from "password-validator";

const usePasswordValidator = (config) => {
    const {
        minPasswordLength
    } = config;

    const passwordValidatorRef = useRef(new PasswordValidator());

    useEffect(
        () => {
            if (minPasswordLength > 0) {
                passwordValidatorRef.current.min(minPasswordLength);
            }
        }, [minPasswordLength]
    );

    return passwordValidatorRef.current;
}

export {usePasswordValidator};