export const MODE_LOGIN = 'LOGIN';
export const MODE_REGISTER = 'REGISTER';
export const MODE_RESTORE_PASSWORD = 'RESTORE';
export const MODE_RESTORE_PASSWORD_CONFIRM = 'RESTORE_CONFIRM';
export const MODE_CONFIRM_EMAIL = 'CONFIRM_EMAIL';
export const EMAIL_PATTERN = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/i;
export const SUBMIT_LABEL = {
    [MODE_LOGIN]: 'Log in',
    [MODE_REGISTER]: 'Register',
    [MODE_RESTORE_PASSWORD]: 'Restore Password',
    [MODE_RESTORE_PASSWORD_CONFIRM]: 'Set New Password',
    [MODE_CONFIRM_EMAIL]: 'Confirm email'
}
export const BLANK_NOTIFICATION = {
    type: 'success',
    message: ''
};