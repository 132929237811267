import React from "react"
import PropTypes from "prop-types"

import {
    QueryClient,
    QueryClientProvider,
} from 'react-query';

import "./../styles/main.scss"
import {UserProvider} from "./user-context";

const queryClient = new QueryClient();

const MainLayout = ({children}) => {
    return (
        <UserProvider>
            <QueryClientProvider client={queryClient}>
                {children}
            </QueryClientProvider>
        </UserProvider>
    )
}

MainLayout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default MainLayout