import {LOGIN_FAILED, LOGIN_SUCCESSFUL, LOGOUT} from "./UserContextConstants";
import Auth from '@aws-amplify/auth';
import {Hub} from "@aws-amplify/core";
import base64url from "base64url";

export {useUserService}

const WINDOWS_GLOBAL = typeof window !== 'undefined' && window;
const OAUTH_REDIRECT_URL = WINDOWS_GLOBAL ? `${window.location.protocol}//${window.location.hostname}${window.location.port ? ':' : ''}${window.location.port}` : undefined;
const NO_OP_FUNCTION = () => {};

let configuration = {
    configured: false
};

const configure = !WINDOWS_GLOBAL ? NO_OP_FUNCTION : (config) => {

    const {
        userPoolRegion,
        userPoolId,
        userPoolWebClientId,
        userPoolOauthDomain,
        minPasswordLength
    } = config;

    Auth.configure({
        region: userPoolRegion,
        userPoolId: userPoolId,
        userPoolWebClientId: userPoolWebClientId,
        mandatorySignIn: false,
        oauth: {
            domain: userPoolOauthDomain,
            scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
            redirectSignIn: OAUTH_REDIRECT_URL,
            redirectSignOut: OAUTH_REDIRECT_URL,
            responseType: 'code'
        },
        federationTarget: 'COGNITO_USER_POOLS'
    });

    Object.assign(configuration , {
        configured: true,
        minPasswordLength
    });
}

async function readUser(dispatch) {
    try {
        const cognitoUser = await Auth.currentAuthenticatedUser({
            bypassCache: true
        });
        const user = _buildUserObject(cognitoUser);
        dispatch({type: LOGIN_SUCCESSFUL, user});
        return user;
    } catch (error) {
        dispatch({type: LOGIN_FAILED, error});
    }
}

async function register(username, password) {
    return Auth.signUp({
        username: username, password: password, attributes: {email: username}
    });
}

async function login(username, password, dispatch) {
    return Auth.signIn(username, password)
        .then(user => dispatch({type: LOGIN_SUCCESSFUL, user: _buildUserObject(user)}))
        .catch(error => {
            dispatch({type: LOGIN_FAILED, error});
            throw error;
        });
}

function socialLogin(provider) {
    const currentLocation = `${window.location.pathname}${window.location.hash}`;
    return Auth.federatedSignIn({
        provider: provider,
        customState: base64url.encode(currentLocation)
    });
}

const listener = async ({payload: {event, data}}) => {
    switch (event) {
        case 'customOAuthState':
            const redirectTo = base64url.decode(data);
            console.log(`Redirecting to: ${redirectTo}`);
            window.location.replace(`${OAUTH_REDIRECT_URL}${redirectTo}`);
            break;
    }
};
Hub.listen('auth', listener);

function logout(dispatch) {
    Auth.signOut({global: true})
        .finally(() => dispatch({type: LOGOUT}))
}

async function restorePassword(email) {
    return Auth.forgotPassword(email);
}

async function confirmEmail(email, code) {
    return Auth.confirmSignUp(email, code);
}

async function resendConfirmEmail(email) {
    return Auth.resendSignUp(email);
}

async function restorePasswordConfirm(email, code, password) {
    return Auth.forgotPasswordSubmit(email, code, password);
}

function _buildUserObject(cognitoUser) {
    return {
        username: cognitoUser.signInUserSession.getIdToken().payload.email,
        attributes: {
            ...cognitoUser.attributes
        },
        valid: cognitoUser.signInUserSession.isValid(),
        getAccessToken: async () => (await Auth.currentSession()).getAccessToken().getJwtToken(),
        getIdToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken(),
    }
}

function useUserService() {
    return {
        configuration,
        configure,
        readUser,
        register,
        login,
        socialLogin,
        logout,
        restorePassword,
        restorePasswordConfirm,
        confirmEmail,
        resendConfirmEmail
    }
}