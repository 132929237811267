import React, {useEffect, useState} from 'react'
import {
    MODE_CONFIRM_EMAIL,
    MODE_LOGIN,
    MODE_REGISTER,
    MODE_RESTORE_PASSWORD,
    MODE_RESTORE_PASSWORD_CONFIRM
} from "./LoginConstants";

const FORM_HEADER = {
    [MODE_LOGIN]: 'Log in',
    [MODE_REGISTER]: 'Register',
    [MODE_RESTORE_PASSWORD]: 'Restore Password',
    [MODE_RESTORE_PASSWORD_CONFIRM]: 'Set New Password',
    [MODE_CONFIRM_EMAIL]: 'Confirm Email',
}

function useLoginModeHandler(mode = MODE_LOGIN){
    const [header, setHeader] = useState(FORM_HEADER[mode]);

    useEffect(() => {
        modeChangeHandler(mode);
    }, [mode]);

    function modeChangeHandler (mode) {
        setHeader(FORM_HEADER[mode]);
    }

    return {header, modeChangeHandler};
}

export {useLoginModeHandler};