const React = require('react');
const {GlobalConfigContext} = require('./global-config-provider');

const useGlobalConfig = () => {
    const context = React.useContext(GlobalConfigContext);
    if (context === undefined) {
        throw new Error('useGlobalConfig must be used within GlobalConfigProvider');
    }
    return context;
}

module.exports = {
    useGlobalConfig
}