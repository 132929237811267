import React from 'react';
import {Modal} from "react-bootstrap";
import {LoginForm} from "./LoginForm";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import {
    MODE_LOGIN,
} from "./LoginConstants";
import {useUserDispatch, useUserState, LOGIN_CANCELLED} from "../user-context";
import {useLoginModeHandler} from "./useLoginModeHandler";

function LoginModal() {

    const {login_requested, mode = MODE_LOGIN} = useUserState();
    const {header, modeChangeHandler} = useLoginModeHandler(mode);
    const dispatch = useUserDispatch();

    function handleHide(){
        dispatch({type:LOGIN_CANCELLED});
    }

    return (
        <Modal show={login_requested} centered={true} size={"sm"} onHide={handleHide}>
            <ModalHeader closeButton={true}>
                <h3 className="modal-title w-100 text-center mb-0 fw-bold">{header}</h3>
            </ModalHeader>
            <ModalBody>
                <LoginForm onModeChange={modeChangeHandler} initialMode={mode}/>
            </ModalBody>
        </Modal>
    );
}

export {LoginModal};