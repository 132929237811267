const React = require('react');

const LOADED_PROP_NAME = 'loaded';
const GLOBAL_CONFIG_URL = '/global/config.json'; //TODO make configurable via plugin config
const GlobalConfigContext = React.createContext(null);

const GlobalConfigProvider = ({children}) => {
    const [config, setConfig] = React.useState({
        [LOADED_PROP_NAME]: false
    });

    React.useEffect(() => {
        _loadGlobalConfig(setConfig);
    },[]);

    return (
        <GlobalConfigContext.Provider value={config}>
            {children}
        </GlobalConfigContext.Provider>
    )
}

const _loadGlobalConfig = (setConfigCallback) =>
    fetch(GLOBAL_CONFIG_URL)
        .then(result => result.json())
        .then(config => {
            delete config[LOADED_PROP_NAME];

            setConfigCallback(Object.assign(config, {
                [LOADED_PROP_NAME]: true
            }))
        });

module.exports = {
    GlobalConfigContext,
    GlobalConfigProvider
}