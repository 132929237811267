import React, {useEffect} from 'react';
import {
    LOGIN_SUCCESSFUL,
    LOGIN_FAILED,
    LOGOUT,
    LOGIN_REQUESTED,
    LOGIN_CANCELLED
} from './UserContextConstants';
import {useUserService} from './UserService';
import {LoginModal} from "../login";
import {useGlobalConfig} from 'gatsby-plugin-global-config';

const UserStateContext = React.createContext(null);
const UserDispatchContext = React.createContext(null);

function _getInitialUserState(user) {
    return {
        user: user,
        initiated: false,
        authenticated: !!user?.token || false,
        error: undefined,
        login_requested: false,
        mode: undefined
    }
}

function _userReducer(state, action) {
    switch (action.type) {
        case LOGIN_REQUESTED:
            return {
                ...state,
                login_requested: true,
                mode: action.mode
            }
        case LOGIN_SUCCESSFUL:
            return {
                ...state,
                user: action.user,
                authenticated: action.user && !!action.user.valid,
                error: undefined,
                login_requested: false,
                mode: undefined,
                initiated: true
            };
        case LOGIN_FAILED:
            return {
                ...state,
                error: action.error.message || action.error,
                user: {},
                authenticated: false,
                initiated: true
            };
        case LOGIN_CANCELLED:
            return {
                ...state,
                login_requested: false,
                mode: undefined
            }
        case LOGOUT:
            return {
                ..._getInitialUserState(undefined),
                initiated: true
            };
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
}

function UserProvider({children}) {
    const user = {};
    const [state, dispatch] = React.useReducer(_userReducer, user, _getInitialUserState);
    const {configure, readUser} = useUserService();
    const {loaded: globalConfigLoaded, ...globalConfig} = useGlobalConfig();

    useEffect(() => {
        if(globalConfigLoaded){
            configure(_buildUserServiceConfigObject(globalConfig));

            readUser(dispatch);
        }
    }, [globalConfigLoaded]);

    return (
        <UserStateContext.Provider value={state}>
            <UserDispatchContext.Provider value={dispatch}>
                {children}
                <LoginModal/>
            </UserDispatchContext.Provider>
        </UserStateContext.Provider>
    );
}

function useUserState() {
    const context = React.useContext(UserStateContext);
    if (context === undefined) {
        throw new Error('useUserState must be used within UserProvider');
    }
    return context;
}

function useUserDispatch() {
    const context = React.useContext(UserDispatchContext);
    if (context === undefined) {
        throw new Error('useUserDispatch must be used within UserProvider');
    }

    return context;
}

function _buildUserServiceConfigObject(globalConfig) {
    return {
        userPoolRegion : globalConfig['user-pool-region'],
        userPoolId: globalConfig['user-pool-id'],
        userPoolWebClientId: globalConfig['user-pool-client-id'],
        userPoolOauthDomain: globalConfig['user-pool-url'],
        minPasswordLength: parseInt(globalConfig['user-pool-password-policy-min-length'])
    }
}

export {UserProvider, useUserState, useUserDispatch};