import React from 'react';
import {LOGIN_REQUESTED, LOGOUT, useUserDispatch, useUserState, useUserService} from "../user-context";
import Nav from "react-bootstrap/Nav";
import PropTypes from "prop-types";
import {Link} from "gatsby";

LoginNavBarItem.propTypes = {
    as: PropTypes.string,
}

function LoginNavBarItem(props) {

    const dispatch = useUserDispatch();
    const { logout } = useUserService();
    const { authenticated } = useUserState();

    function handleLogoutClick(e) {
        e.preventDefault();
        logout(dispatch);
    }

    return authenticated ? (
        <Nav.Item {...props}>
            <Nav.Link href="#" onClick={handleLogoutClick}>
                Log Out
            </Nav.Link>
        </Nav.Item>
    ) : (
        <Nav.Item as={"li"}{...props}>
            <Nav.Link as={Link} to="/login">
                Log In
            </Nav.Link>
        </Nav.Item>
    );
}

export {LoginNavBarItem};